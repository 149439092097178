import * as Sentry from '@sentry/nextjs';
import dynamic from 'next/dynamic';

import AuthGuard from '@/components/AuthGuard/AuthGuard';
import { getLocale } from '@/context/intlContext';
import { getParsedAcceptLangs } from '@/helpers/i18n';
import strapiApi from '@/helpers/strapi/api';
import DefaultLayout from '@/layouts/default';

const Page = dynamic(() => import('@/templates/Page/Page'));

const Login = ({ page, refererPathname }) => (
  <DefaultLayout showB2BModal={false}>
    <AuthGuard isPublic redirectTo="/bookings">
      <Page page={page} refererPathname={refererPathname} />
    </AuthGuard>
  </DefaultLayout>
);

export async function getServerSideProps({ req, resolvedUrl }) {
  const [pathname] = resolvedUrl.split('?');
  const acceptLanguages = getParsedAcceptLangs(
    req?.headers?.['accept-language']
  );
  const referer = req.headers?.referer;
  let refererPathname;

  const locale = getLocale(acceptLanguages);
  const pageData = (await strapiApi.getPageByPathname(pathname, locale)).data
    .data;

  if (referer) {
    try {
      refererPathname = new URL(referer).pathname;
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  return {
    props: {
      page: pageData,
      refererPathname: refererPathname || null,
    },
  };
}

export default Login;
